import { SHARED_PROGRAM_API_URL } from '../env'
import buildAPIClient from './build-client'

const programServiceApiClient = buildAPIClient(SHARED_PROGRAM_API_URL)

const healthcareApi = {
  loadHealthcareProviderProfile () {
    return programServiceApiClient
      .get('/healthcare-api/providers/me')
      .then(function (response) {
        return response.data.healthcareProvider
      })
  },

  searchPatients (params = {}) {
    return programServiceApiClient
      .get('/healthcare-api/patients', { params })
      .then(function (response) {
        return response.data
      })
  },

  searchRenderedServices (params = {}) {
    return programServiceApiClient
      .get('/healthcare-api/rendered-services', { params })
      .then(function (response) {
        return response.data
      })
  },

  updateRenderedService (id, params = {}) {
    return programServiceApiClient
      .put(`/healthcare-api/rendered-services/${id}`, params)
      .then(function (response) {
        return response.data
      })
  }
}

export default healthcareApi
